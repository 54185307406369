export default [
 
  {
    type: 'selection',
    width: 38,
    align: 'center'
},
 {
    title: "Name",
    key: "Name",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "Month",
    key: "Month",
    sortable: true,
    minWidth: 80,
  },
  {
    title: "Year",
    key: "Year",
    sortable: true,
    minWidth: 80,
  },
  {
    title: "Payment",
    key: "Payment",
    // sortable: true,
    minWidth: 80,
  },
  {
    title: "Amount",
    key: "amount",
    sortable: true,
    minWidth: 80,
  },
];
